.suggestions {
  border: 1px solid #999;
  border-top-width: 0px;
  list-style: none;
  margin-top: -7px;
  max-height: 300px;
  overflow-y: auto;
  padding-left: 0;
  position: absolute;
  left: -1px;
  right: -1px;
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;
  z-index: 9999;
  padding: 7.5px 7.5px;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: max-content;
}

.suggestions li {
  padding: 7.5px 7.5px 7.5px 7.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  /* border-bottom: 1px solid #999; */
}

.asdecor {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.asdecor.active .asdecorinner {
  width: 12px;
  height: 12px;
  border-radius: 3px;
  background-color: #08ab4c;
}
