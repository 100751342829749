.tradebot-v1 {
  overflow: unset;
  text-align: left;
}

.tradebot-v1 div {
  overflow: unset;
}

.tradebot-v1 .tradebot-config {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3333;
  flex-grow: 1.5;
  min-width: 350px;
}

.tradebot-v1 .tradebot-dash {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3333;
  flex-grow: 1;
  min-width: 350px;
  text-align: right;
}

.tradebot-v1 .nav-tabs .nav-item {
  margin-bottom: -8px;
}

.tradebot-v1 .tradebot-dash .action-panel {
  display: flex;
  justify-content: space-between;
}
.tradebot-v1 button {
  margin: 1px;
}

.tradebot-v1 .config-row {
}

.tradebot-v1 .config-box {
  /* border: 1px solid gray; */
  /* border-radius: 5px; */
  cursor: pointer;
  display: flex;
}

.tradebot-v1 .config-box .label {
  padding-right: 10px;
  padding-left: 20px;
  font-weight: 700;
}

.modal-dialog.configbox-modal.large {
  width: 95%;
  max-width: none !important;
}
.modal-dialog.configbox-modal.maxwidth600 {
  max-width: 600px;
}

.modal-dialog.appmodal.medium {
  width: 60%;
  min-width: 300px;
  max-width: none !important;
}
