.leadbutton {
  width: 95px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #bbc3d3;
  border-radius: 7px;
  margin-left: 15px;
  cursor: pointer;
  position: relative;
}

.leadbutton:hover {
  background-color: #f7f8f9;
}

.leadbuttonico {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.leadbuttontext {
  color: #8998a5;
}

.leadsortpopup {
  width: 280px;
  border: 1px solid #bbc3d3;
  border-radius: 8px;
  margin-top: 10px;
  z-index: 9999;
  background-color: #ffffff;
  /* box-shadow: 0px 1px 6px rgba(0,0,0,0.10); */
}

.orderbody .orderunit:nth-last-child(1) .orderoption {
  border-bottom: 0px solid;
}

.orderunit {
  margin: 0px;
  border-top: 0px solid #eee;
}
.orderoption {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 7.5px 7.5px 7.5px 7.5px;
  padding: 7.5px 0px 7.5px 0px;
  border-bottom: 0px solid #eeeeee;
  color: #9e9e9e;
  background-image: url("../../../Assets/Images/plusitem.png");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: right 0px center;
  font-weight: 500;
  background: #ffffff;
  padding: 10px 10px;
  border-radius: 8px;
}
.ordersubunit {
  display: flex;
  font-size: 12px;
  padding: 2px 5px;
  background-color: #16a217;
  margin-left: 5px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 400;
}
.orderunit.active .orderoption {
  font-weight: 500;
  color: #000000;
}
.orderbody .orderunit:nth-last-child(1) .orderoption {
  border-bottom: 0px solid;
}

.filterelement {
  margin: 0px;
}
.sortfilterunit {
  margin: 7.5px 7.5px 7.5px 7.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  border-bottom: 0px solid #eeeeee;
  color: #9e9e9e;
  font-weight: 500;
  background-image: url("../../../Assets/Images/plusitem.png");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: right 10px center;
  text-transform: capitalize;
}
.filterelement .sortfilterunit:nth-last-child(1) {
  border-bottom: 0px solid;
}

.cfactionwrap {
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
  padding-top: 0px;
}
.cfbutton {
  color: #ffffff;
  background-color: rgb(24 34 43);
  padding: 15px 20px;
  border-radius: 10px;
  margin-top: 5px;
  text-align: center;
}

.sorttabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px;
  background-color: #bbc3d3;
  border-radius: 10px;
}
.sorttabs div {
  display: flex;
  flex: 1 1;
  margin: 2px;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 8px;
  color: #000;
  font-weight: 600;
}
.sorttabs .sorttabactive {
  color: #000000;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04);
}

.datepickbutton {
  display: flex;
  cursor: pointer;
}
.greenchev {
  width: 24px;
  height: 24px;
}
.datepickbutton.two .greenchev {
  transform: rotate(180deg);
}
.datepickbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.datepickbar .custom-date-input {
  font-weight: 500;
  font-size: 20px;
}
.datepickdecor {
  height: 2px;
  width: 6px;
  background-color: #000000;
  margin: 0px 10px;
}
.datepickbutton.one {
  margin-right: 15px;
}
.datepickbutton.two {
  margin-left: 15px;
}

.alinputbox {
  width: 100%;
  height: 45px;
  border: 0px solid;
  border-radius: 10px;
  padding-left: 15px;
  background-color: transparent;
}

.sfubox {
  margin: 15px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #bcc3d3;
  position: relative;
}
.sfubox .suggestions {
  margin-top: 2px;
  border-color: #bcc3d3;
}
.sfulabelbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.sfulabel {
  font-weight: 500;
  color: #000000;
  text-transform: capitalize;
}
.sfuclose {
  width: 20px;
  height: 20px;
  border-radius: 999px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sfucdecor {
  width: 10px;
  height: 2px;
  background-color: #ffffff;
  border-radius: 999px;
}
.sfubox input {
  height: 20px;
  color: #444444;
  padding-left: 0px;
  margin-top: 5px;
  background-color: transparent;
  border-radius: 4px;
  border: 0px;
  border-bottom: 0px solid #071042;
  border-radius: 0px;
  text-indent: 0px;
  font-weight: 400;
}
@media (max-width: 1100px) {
  .leadbutton {
    width: 80px;
  }
}

@media (max-width: 1000px) {
  .leadbutton {
    width: 80px;
  }

  .leadbuttontext {
    display: none;
  }
}

@media (max-width: 1000px) {
  .leadbutton {
    width: 40px;
  }
}

@media (max-width: 790px) {
  .leadbutton {
    width: 40px;
    margin-left: 5px;
  }
}
