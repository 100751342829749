.gp1 {
  height: 100px;
  width: 110px;
  border: 1px solid lightblue;
}
.gp2 {
  flex: 1;
  height: 100px;
  border: 1px solid lightblue;
}

.gp1t {
  background: lightcyan;
  color: darkblue;
}

.gp1g {
  padding: 10px;
}

.gp2g {
  padding: 0 2px;
}

input.gp1gi {
  width: 100%;
  border: 1px solid lightblue;
  text-align: right;
  border-radius: 5px;
}

select.gp1gs {
  width: 100%;
  border: 1px solid lightblue;
  text-align: right;
  border-radius: 5px;
}

.modal-dialog.daywiserate-modal {
  width: 95%;
  max-width: none !important;
}

.modal-dialog.minuteinterval-modal {
  width: 40%;
  max-width: none !important;
}
