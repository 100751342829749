@font-face {
  font-family: "Sans";
  src: url("../Assets/fonts/googlesans-regular-webfont.woff2") format("woff2"),
    url("../Assets/fonts/googlesans-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Sans";
  src: url("../Assets/fonts/googlesans-medium-webfont.woff2") format("woff2"),
    url("../Assets/fonts/googlesans-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Sans";
  src: url("../Assets/fonts/googlesans-bold-webfont.woff2") format("woff2"),
    url("../Assets/fonts/googlesans-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
body {
  margin: 0;
  font-size: 14px;
  font-family: "Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button {
  cursor: pointer;
}
input,
button,
select,
textarea {
  font-family: "Sans";
  padding: 0px;
  outline: none;
}
.centerify {
  align-self: center;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}
.centerify.abs {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loader {
  border: 8px solid #3803f8; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}
.loader.tiny {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #3498db; /* Blue */
  width: 15px;
  height: 15px;
}
.errormsg {
  color: red;
  text-align: center;
}

.leadsearchitem {
  height: 100%;
}
.balance-chart-container {
  padding: 20px;
  width: 50%;
}
.flex {
  display: flex;
}

@media (max-width: 1100px) {
  .balance-chart-container {
    padding: 0;
    width: 100%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
